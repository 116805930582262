import gql from "graphql-tag";
import {ComplianceTicketFragment} from "../../../Queries/Compliance";
import {
    ComplianceTicket, ComplianceTicketFragmentFragment,
    MangoQueryResult, ModifyPersonComplianceTicketInput, MutationModifyPersonComplianceTicketsArgs,
    QueryPersonComplianceTicketsArgs
} from "../../../__generated__/graphql/types";
import {useMutation, useQuery} from "react-apollo";

const QUERY_PERSON_COMPLIANCE_TICKETS = gql`
query ComplianceEditor_PersonComplianceTickets(
    $personID: ID!
    $limit: Int
    $skip: Int
    $bookmark: String
){
    PersonComplianceTickets(personID: $personID, limit: $limit, bookmark: $bookmark, skip: $skip){
        docs {
            ... on ComplianceTicket {
                ...ComplianceTicketFragment
            }
        }
        bookmark
    }
}
${ComplianceTicketFragment}
`

export interface UseComplianceDataArgs {
    personID: string,
    limit?: number,
    skip?: number,
    bookmark?: string
}

export function usePersonComplianceTicketsQuery(args: UseComplianceDataArgs) {

    type QueryResult = {
        PersonComplianceTickets: MangoQueryResult
    }

    const result = useQuery<QueryResult, QueryPersonComplianceTicketsArgs>(QUERY_PERSON_COMPLIANCE_TICKETS, {
        variables: {
            personID: args.personID,
            limit: args.limit,
            skip: args.skip,
            bookmark: args.bookmark
        },
        skip: !(args?.personID),
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    })

    return {
        ...result,
        data: result.data?.PersonComplianceTickets?.docs as ComplianceTicketFragmentFragment[]
    }
}

const MUTATION_MODIFY_PERSON_COMPLIANCE_TICKETS = gql`
    mutation ComplianceEditor_ModifyPersonComplianceTickets(
        $personID: ID!
        $updateTickets: [ModifyPersonComplianceTicketInput!]
        $deleteTicketIds: [ID!]
    ) {
        ModifyPersonComplianceTickets(
            personID: $personID,
            updateTickets: $updateTickets
            deleteTicketIds: $deleteTicketIds
        ) {
            ...ComplianceTicketFragment
        }
    }
    ${ComplianceTicketFragment}
`

export function usePersonComplianceTicketsMutation(){

    const [ _mutation, result ] = useMutation(MUTATION_MODIFY_PERSON_COMPLIANCE_TICKETS);

    async function mutation(args: MutationModifyPersonComplianceTicketsArgs){
        return _mutation({
            variables: {
                ...args
            }
        })
    }

    return [ mutation, result ] as [ typeof mutation, typeof result]
}