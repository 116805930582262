import {ComplianceTicketFormData} from "./types";
import {produce} from "immer";
import {ComplianceTicket, ComplianceTicketFragmentFragment, Customer} from "../../../__generated__/graphql/types";
import {getCollapsePanelKey, toFormData} from "./util";
import uuidv4 from "uuid/v4";
import React, {Dispatch} from "react";
import {createFormField, LabelInValue} from "../../../common/form";

export const DefaultState = {
    formState: new Map<string, ComplianceTicketFormData>(),
    ticketsDirty: false,
    collapseActiveKeys: [] as string[]
}

export type State = typeof DefaultState


export type Action = {
    type: 'UPDATE_TICKET',
    payload: {
        data: ComplianceTicketFragmentFragment | ComplianceTicketFormData,
        internalId?: string
    }
} | {
    type: 'INIT_TICKETS',
    payload: ComplianceTicketFragmentFragment[] | ComplianceTicketFormData[]
} | {
    type: 'ADD_BLANK_TICKET',
    payload: {
        customerDoc: Customer,
        personID: string,
        makeVisible?: boolean
    }
} | {
    type: 'REMOVE_TICKET',
    payload: {
        internalId: string
    }
} | {
    type: 'COLLAPSE_SET_ACTIVE_KEYS',
    payload: string[]
} | {
    type: 'COLLAPSE_SET_VISIBILITY',
    payload: {
        key: string,
        visible: boolean
    }
}

const TicketsReducer: React.Reducer<State, Action> = (_state, action) => {
    return produce(_state, (draft) => {
        draft.ticketsDirty = true;
        switch (action.type){
            case "INIT_TICKETS":
                draft.ticketsDirty = false;
                draft.formState = new Map(DefaultState.formState);
                action.payload.forEach((ticket: ComplianceTicketFragmentFragment | ComplianceTicketFormData) => {
                    const ticketForm = toFormData(ticket);
                    draft.formState.set(ticketForm.internalId, ticketForm);
                })
                return draft
            case "UPDATE_TICKET": {
                const ticketForm = toFormData(action.payload.data, action.payload.internalId);
                const payloadCustomerID = ticketForm.customerIDAndName?.value?.key;
                const prevCustomerID = _state?.formState.get(ticketForm.internalId)?.customerIDAndName?.value?.key;

                if (payloadCustomerID !== prevCustomerID){
                    // Customer changed. Reset compliance rule selection.
                    // TODO: This doesn't work for some reason?
                    try {
                        if (ticketForm.complianceRuleIDAndName?.value){
                            ticketForm.complianceRuleIDAndName = createFormField<LabelInValue>(null);
                        }
                    }
                    catch (e){
                        console.error('Handled error setting complianceRuleIDAndName', e);
                    }
                }

                draft.formState.set(ticketForm.internalId, ticketForm);
                return draft
            }
            case "ADD_BLANK_TICKET": {
                const blankTicket: ComplianceTicket = {
                    customerID: action.payload.customerDoc?._id,
                    customerDoc: action.payload.customerDoc,
                    personID: action.payload.personID,
                    _id: null,
                    complianceRuleID: null,
                    complianceRuleDoc: null,
                    nextComplianceDate: null,
                    lastComplianceDate: null
                }

                const internalId = uuidv4();

                const ticketForm = toFormData(blankTicket, internalId);

                let state = Reducer(_state, {
                    type: 'UPDATE_TICKET',
                    payload: {
                        data: ticketForm
                    }
                });

                if (action.payload.makeVisible) {
                    const action: Action = {
                        type: 'COLLAPSE_SET_VISIBILITY',
                        payload: {
                            key: getCollapsePanelKey(ticketForm),
                            visible: true
                        }
                    }
                    state = Reducer(state, action);
                }

                return Object.assign(draft, state);
            }
            case "REMOVE_TICKET":
                draft.formState.delete(action.payload.internalId);
                return draft;
        }
    })
}

export const Reducer: React.Reducer<State, Action> = (_state, action) => {
    return produce(_state, (draft) => {
        switch (action.type){
            case "COLLAPSE_SET_ACTIVE_KEYS":
                draft.collapseActiveKeys = action.payload;
                return draft;
            case "COLLAPSE_SET_VISIBILITY":
                if (action.payload.visible && !_state.collapseActiveKeys.includes(action.payload.key)){
                    draft.collapseActiveKeys.push(action.payload.key);
                }
                else if (!action.payload.visible && _state.collapseActiveKeys.includes(action.payload.key)){
                    draft.collapseActiveKeys = draft.collapseActiveKeys.filter((key) => key !== action.payload.key);
                }

                return draft;
            default:
                return TicketsReducer(_state, action);
        }
    })
}

export interface ComplianceEditorApiSideEffects {
    onResetTickets: () => void
}

export class ComplianceEditorApi {

    private readonly dispatcher: Dispatch<Action>
    private readonly state: State;
    private readonly sideEffects: ComplianceEditorApiSideEffects;

    constructor(state: State, dispatcher: Dispatch<Action>, sideEffects?: ComplianceEditorApiSideEffects) {
        this.state = state;
        this.dispatcher = dispatcher;
        this.sideEffects = sideEffects;
    }

    public addBlankTicket = (customerDoc: Customer, personID: string, options?: { makeVisible?: boolean }) => {
        this.dispatcher({ type: 'ADD_BLANK_TICKET', payload: { customerDoc, personID, makeVisible: options?.makeVisible } });
    }

    public updateTicket = (ticketData: ComplianceTicket | ComplianceTicketFormData) => {
        this.dispatcher({ type: 'UPDATE_TICKET', payload: { data: ticketData } });
    }

    public removeTicket = (ticket: ComplianceTicketFormData | string) => {
        let formId = typeof ticket === 'string' ? ticket : ticket?.internalId;
        this.dispatcher({ type: 'REMOVE_TICKET', payload: { internalId: formId } })
    }

    public resetTickets = () => {
        if (typeof this.sideEffects?.onResetTickets === 'function'){
            this.sideEffects.onResetTickets();
        }
    }

    public setCollapseKeys = (keys: string[]) => {
        this.dispatcher({ type: 'COLLAPSE_SET_ACTIVE_KEYS', payload: keys })
    }

    public setTicketVisibility = (ticket: ComplianceTicketFormData, visible: boolean) => {
        const key = getCollapsePanelKey(ticket);
        this.dispatcher({ type: 'COLLAPSE_SET_VISIBILITY', payload: { key, visible } })
    }

    public toggleTicketVisibility = (ticket: ComplianceTicketFormData) => {
        const key = getCollapsePanelKey(ticket);
        this.setTicketVisibility(ticket, !(key in this.state.collapseActiveKeys))
    }

    public areTicketsDirty = () => {
        return this.state.ticketsDirty;
    }
}