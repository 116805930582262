import {Drawer} from "antd";
import React from "react";
import {DrawerProps} from "antd/lib/drawer";
import useBreakpoints from "../../../../hooks/useBreakpoints";

export interface SelectPanelDrawerProps extends DrawerProps {}

export const SelectPanelDrawer: React.FC<SelectPanelDrawerProps> = (props) => {

    const bp = useBreakpoints();

    return (
        <Drawer
            width={bp.min('medium') ? 1048 : '100%'}
            {...props}
        />
    )
}

export default SelectPanelDrawer;