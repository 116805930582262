import {Alert, Button, Select} from "antd";
import {useCustomerComplianceRuleQuery} from './graphql';
import React, {useContext} from "react";
import {LabelInValue} from "../../../../common/form";
import cn from 'classnames';
import './compliance-rule-select.style.less';
import {ComplianceRuleSelectApiContext, useComplianceRuleSelectApi} from "./state";
import SelectPanelDrawer from "./select-panel-drawer";
import SelectPanel from "./select-panel";

export interface ComplianceRuleSelectProps {
    customerId: string,
    value?: LabelInValue,
    disabledComplianceRuleIds?: string[],
    disabledMessage?: string,
    onChange?: (value: LabelInValue) => void
}

const ComplianceRuleSelect: React.FC<ComplianceRuleSelectProps> = ({
    customerId,
    value,
    onChange,
    disabledComplianceRuleIds,
    disabledMessage
}) => {

    const ctx = useContext(ComplianceRuleSelectApiContext);
    const controlled = !!ctx;

    const queryResult = useCustomerComplianceRuleQuery({
        customerID: customerId,
        limit: 9999999
    });
    const _api = useComplianceRuleSelectApi(
        queryResult?.data
    );

    const api = controlled ? ctx : _api;

    let drawer: React.ReactNode = null;

    function handleRuleChanged(ruleID: string, ruleName: string){
        if (typeof onChange === 'function'){
            api.closePanel();
            onChange({
                key: ruleID,
                label: ruleName
            });
        }
    }

    if (!controlled){
        drawer = (
            <SelectPanelDrawer
                title="Select a compliance rule"
                visible={api.state.drawerOpen}
                onClose={api.closePanel}
                bodyStyle={{
                    padding: 0
                }}
                className="ant-drawer-flex"
                destroyOnClose
            >
                <SelectPanel
                    api={api}
                    onRuleSelected={handleRuleChanged}
                    disabledComplianceRuleIds={disabledComplianceRuleIds}
                    disabledMessage={disabledMessage}
                />
            </SelectPanelDrawer>
        )
    }

    function onClicked(){
        api.openPanel(value?.key);
    }

    return (
        <>
        <Button className="ant-input" onClick={onClicked}>
            <div className="compliance-rule-select">
                <span>{value?.label}</span>
                <span className="compliance-rule-select-change-text">
                    Change
                </span>
            </div>
        </Button>
        {drawer}
        </>
    )
}

export default ComplianceRuleSelect