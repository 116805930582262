import { Form } from "antd";
import { isObject, mapValues, isEmpty } from 'lodash';
import emailValidator from 'email-validator';

export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        }
    }
}

export function createFormField<D=any>(data: D | { value: D, [key: string]: any }, useDataAsValue?: boolean){
    if ((typeof data === 'object' && !isEmpty(data) && data !== null && !('value' in data) && !('dirty' in data)) || typeof data !== 'object' || data === null || useDataAsValue){
        return Form.createFormField({
            value: data
        }) as FormField<D>
    }
    return Form.createFormField({
        ...(data || {}),
        value: data && (data as any).value
    }) as FormField<D>
}


export type AsFormData<T> = { [K in keyof T]: FormField<T[K]> }

export function createFormFields<T extends object=any>(fields: T){
    if (!fields) return undefined;
    let data: AsFormData<T> = {} as AsFormData<T>
    for (let key in fields) {
        const value = fields[key];
        data[key] = createFormField(value);
    }
    return data
}

export interface LabelInValue {
    label: string,
    key: string
}

export interface FormField<T=any> {
    value: T,
    touched?: boolean,
    [key: string]: any
}

export interface FormFields {
    [key: string]: FormField | string | number
}

export interface FormErrors {
    [field: string]: {
        errors: { field: string, message: string }
    }
}

export function getFormFieldValue(field: FormField | string | number){
    let value: any;
    if (field !== null && typeof field === 'object' && field.value !== undefined){
        value =  field.value;
    }
    else if (field !== null && typeof field === 'object' && field.value === undefined){
        value = undefined;
    }
    else
    {
        value = field
    }
    if (isObject(value) && isEmpty(value)){
        value = undefined
    }
    return value
}

export function getFormFieldsValues(fields: FormFields){
    if (!isObject(fields)){
        return fields
    }
    return mapValues(fields, getFormFieldValue);
}

export function formFieldsValuesHaveChanged(oldFormFields: any, newFormFields: any){

    let oldValues = getFormFieldsValues(oldFormFields);
    let newValues = getFormFieldsValues(newFormFields);

    console.debug('detecting changes: ', oldValues, newValues);

    // Converts objects with "key" property for fields that use labelInValue prop
    const convertKeyLabel = (value: any) => {
        if (typeof value !== 'object' || value === null || value === undefined){
            return value;
        }
        if ('key' in value){
            return value.key;
        }
        
        return value;
    }

    const findChanged = (key: string, value: any, fv: any) => {
        if (fv === undefined) return true;
        if (!(key in fv)){
            return true; // Key not found in other form fields. Must've been added.
        }
        let otherValue = fv[key];
        if (typeof value === 'object' && value !== null){
            value = convertKeyLabel(value);
            value = String(value);
        }
        if (typeof otherValue === 'object' && otherValue !== null){
            otherValue = convertKeyLabel(otherValue);
            otherValue = String(otherValue);
        }
        return otherValue !== value;
    }

    let changed = false;

    // Compare new form fields vs old
    let newKeys = newValues ? Object.keys(newValues) : [];
    for (let i = 0; i < newKeys.length; i++) {
        const key = newKeys[i];
        if (changed)
            break
        changed = findChanged(key, newValues[key], oldValues);
    }

    // Compare old form fields vs new
    let oldKeys = oldValues ? Object.keys(oldValues) : [];
    for (let i = 0; i < oldKeys.length; i++) {
        const key = newKeys[i];
        if (changed)
            break
        changed = findChanged(key, oldValues[key], newValues);
    }

    if (changed) console.debug('changes detected!');
    if (changed) return true;
    console.debug('change not detected', oldValues, newValues);
    return false;
}

export function formFieldsIsTouched(fields: FormFields, recursiveLevels: boolean | number=false){
    if (!fields) return false;
    return Object.values(fields).findIndex((field) => {
        if (!isObject(field)) return false

        if (field.touched){
            return field.touched
        }
        if (recursiveLevels){
            let nextRecursiveLevel: boolean | number = recursiveLevels;
            if (typeof recursiveLevels === 'number'){
                nextRecursiveLevel = recursiveLevels - 1;
            }
            return formFieldsIsTouched(field.value, nextRecursiveLevel)
        }
        return false
    }) !== -1
}

export function transformFormFieldsValues(fields: FormFields, fn: (value: any) => any){
    function transformField(field: FormField){
        if (!field) return field;
        let value = getFormFieldValue(field);
        if (!value) return field;
        value = fn(value);
        return {
            ...field,
            value
        }
    }
    return mapValues(fields, transformField)
}

export function getLabelInValue<T extends object>(obj: T, labelKey: keyof T | any, objKey: string='_id'): LabelInValue{
    if (!obj){
        return undefined;
    }
    return {
        key: obj[objKey],
        label: obj[labelKey]
    }
}

export function castToLabelInValue(obj: any){
    if (!obj) return undefined;
    if (typeof obj === 'object' && obj.key && obj.label){
        return obj;
    }

    return { key: obj, label: '' }
}

export function getLabelInValueKey(obj?: { label: any, key: any }){
    if (isObject(obj)){
        return obj.key
    }
    return obj
}

export function uppercaseField(field: FormField | string | number){
    if (field !== null && typeof field === 'object'){
        let val = field.value;
        if (typeof val === 'string'){
            val = val.toUpperCase();
        }
        return {
            ...field,
            value: val
        }
    }
    let val = field;
    if (typeof val === 'string'){
        val = val.toUpperCase();
    }
    return val
}

export function uppercaseAllFields(fields: FormFields){
    return transformFormFieldsValues(fields, (value) => {
        if (typeof value !== 'string') return value
        return value.toUpperCase();
    })
}

export function uppercaseFields(fields: FormFields, fieldNames?: Array<string>){
    if (!fieldNames || fieldNames.length <= 0){
        return fields
    }
    let uppercased = {};
    fieldNames.forEach((fieldName) => {
        uppercased[fieldName] = uppercaseField(fields[fieldName]);
    })
    return {
        ...fields,
        ...uppercased
    }
}

export function mergeValuesIntoFormFields(newValues: any, fields: FormFields){
    let newValueEntries = Object.entries(newValues);

    let newFields = { ...fields }

    newValueEntries.forEach(([ k, v ]) => {
        if (k in fields){
            let field = Object.assign({}, fields[k]); // Create a copy
            if (typeof field === 'object'){
                field.value = v
            }
            newFields[k] = field
        }
        else
        {
            newFields[k] = createFormField(v);
        }
    })

    return newFields
}

/**
 * Gets the value of a field. Also gets the value of the 'key' property if an object for labelInValue fields.
 */
export function getFieldKey(fieldValue: { key: any } | any){
    if (typeof fieldValue === 'object' && fieldValue.hasOwnProperty('key')){
        return fieldValue.key;
    }
    return fieldValue;
}

export function validateLabelInValue(rule, value, callback){
    if (rule.required && !getFieldKey(value)){
        callback(rule.message);
    }
    else
    {
        callback();
    }
}

export function requiredRule(msg="This field is required"){
    return [{ required: true, message: msg }]
}

export function validateEmail(rules, value, callback){
    if(emailValidator.validate(value))
        callback()
    else
        callback("Invalid email format")
}

export const FormItemClassNames = {
    NoMargin: "ant-form-item-no-margin"
}