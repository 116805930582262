import { Icon, Layout, Menu } from 'antd';
import windowSizes from 'common/windowSizes';
import 'components/admin/sidebar-nav/AdminSubMenu';
import AdminSubMenu from 'components/admin/sidebar-nav/AdminSubMenu';
import MCIcon from 'components/icon';
import { GlobalAppStateContext } from 'context/global-app-state';
import usePersistence from 'hooks/persistence';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withUserGroups } from '../userGroup';
import WithOrgData from '../WithOrgData';
import 'css/Navigation.less';
import useUserGroups from 'hooks/useUserGroups';

const { Sider } = Layout;

const basePath = '/app/'

const mcItems = [
  {
    path: basePath + 'dashboard',
    key: 'dashboard',
    icon: 'play-circle',
    text: 'ISN Dashboard'
  },
  {
    path: basePath + 'scheduling',
    icon: 'calendar',
    text: 'Schedule',
    groups: ["flytsuite.schedule.sets", "flytsuite.schedule.flight", "flytsuite.schedule.passenger", "flytsuite.schedule.passenger.view"],
    onlyCustomer: true
  },
  {
    path: basePath + 'flights',
    icon: 'helicopter',
    text: 'Flights'
  },
  {
    path: basePath + 'passengers',
    icon: 'team',
    text: 'Passengers'
  },
  {
    path: basePath + 'cargo',
    icon: 'right-square',
    text: 'Cargo'
  },
  {
    path: basePath + 'tsacheck',
    icon: 'check',
    text: 'TSA Check',
    onlyTransporter: true
  },
  {
    path: basePath + 'flightTimes',
    icon: 'clock-circle',
    text: 'Flight Times',
    groups:["flytsuite.flight.times"]
  }
]

  const navItems = [  
    {
      path: basePath + 'quartermaster',
      icon: 'clock-circle',
      text: 'Quartermaster',
      groups:["flytsuite.quartermaster"],
      onlyCustomer: true
    }
  ];
  const flytwatchItems = [
    {
      path: basePath + 'flytwatch',
      text: 'Home',
      groups: ['flytsuite.flytwatch.view'],
      evalPathsFirst: [
        basePath + 'flytwatch/history',
        basePath + 'flytwatch/historical-reporting',
        basePath + 'flytwatch/fuel-taken'
      ]
    },
    {
      path: basePath + 'flytwatch/history',
      rootPath: basePath + 'flytwatch',
      text: 'Approve Flights',
      groups: ['flytsuite.flytwatch.history.read']
    },
    {
      path: basePath + 'flytwatch/historical-reporting',
      rootPath: basePath + 'flytwatch',
      text: 'Historical Reporting',
      groups: ['flytsuite.flytwatch.history.read']
    },
    {
      path: basePath + 'flytwatch/fuel-taken',
      rootPath: basePath + 'flytwatch',
      text: 'Fuel Taken',
      onlyTransporter: true,
      groups: ['flytsuite.flytwatch.view']
    }
  ]
  const reportItems = [
    {
      path: basePath + 'report/covid',
      text: 'Covid'
    },
    {
      path: basePath + 'report/manifest',
      text: 'Manifest'
    },
    {
      path: basePath + 'report/passenger',
      text: 'Passenger'
    },
    {
      path: basePath + 'report/vaccinated-passengers',
      text: 'Vaccinated Pax',
      groups: ['flytsuite.covid19vaxrecords.read']
    },
    {
      path: basePath + 'report/personnel-vax-details',
      text: 'Personnel Vax Details',
      groups: ['flytsuite.covid19vaxrecords.read'],
      onlyCustomer: true
    },
    {
      path: basePath + 'report/cargo',
      text: 'Cargo'
    },
    {
      path: basePath + 'report/isn',
      text: 'ISN'
    },
    {
      path: basePath + 'report/hotfuel',
      text: 'Hot Fuel',
      groups: ['flytsuite.report.hotfuel.all']
    },
    {
      path: basePath + 'report/pax-isn-events',
      text: 'Pax ISN Events',

      // TODO: Does this need its own user groups?
      groups: ['flytsuite.master.all', 'flytsuite.management.all', 'flytsuite.management.emailtriggers.all']
    }
  ]
  
  const mdItems = [
    {
      path: basePath + 'masterdata/whitelist',
      text: 'ISN White List',
      onlyCustomer: true
    },
    {
      path: basePath + 'masterdata/nonpreferred',
      text: 'Non-Preferred List',
      onlyCustomer: true
    },
    {
      path: basePath + 'masterdata/aircraft',
      text: 'Aircraft',
      onlyTransporter: true,
      groups: ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']
    },
    {
      path: basePath + 'masterdata/contract',
      text: 'Contracts',
      onlyTransporter: true,
      groups: ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']
    },
    {
      path: basePath + 'masterdata/customer',
      text: 'Customers',
      onlyTransporter: true,
      groups: ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']
    },
    {
      path: basePath + 'masterdata/employer',
      text: 'Employers',
      groups: ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']
    },
    {
      path: basePath + 'masterdata/location',
      text: 'Locations',
      onlyTransporter: true,
      groups: ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']
    },
    {
      path: basePath + 'masterdata/pilot',
      text: 'Pilots',
      onlyTransporter: true,
      groups: ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']
    },
    {
      path: basePath + 'masterdata/personnel',
      text: 'Personnel',
      groups: [
        'flytsuite.master.all',
        'flytsuite.master.edit',
        'flytsuite.master.delete',
        'flytsuite.master.person.all'
      ]
    },
  ]

  const managementItems = [
    {
      path: basePath + 'management/email-triggers',
      text: 'Email Triggers',
      onlyCustomer: true,
      groups: ['flytsuite.master.all', 'flytsuite.management.all', 'flytsuite.management.emailtriggers.all']
    }
  ]

  const complianceItems = [
    {
      path: basePath + 'compliance/personnel',
      text: 'Personnel',
      onlyCustomer: true,
      groups: ['flytsuite.compliance.ticket.all']
    }
  ]

  const adminItems = [
    {
      path: basePath + 'admin/user'
    },
    {
      path: basePath + 'admin/fw-billing-report'
    },
    {
      path: basePath + 'admin/fw-changes-by-console'
    }
  ]

const FlightsNav = (props) => {
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);
    const { persistentData, setPersistentValues } = usePersistence('mc.flights.sidenav');
    const [ hasExpanded, setHasExpanded ] = useState(false);
    const [ userGroups ] = useUserGroups();

    useEffect(() => {
      if (!sidebarCollapsed && !hasExpanded){
        setHasExpanded(true);
      }
    }, [ sidebarCollapsed ])

    const { customer } = props.orgData;
    const { pathname } = props.location;
    const renderMenuItems = (data) => (
        data.map(item => {
            var authorized = false;
            if (item.groups){
              item.groups && item.groups.forEach(group => {
                if (props.userGroups.includes(group)){
                  authorized = true;
                }
            })
            }else{
              authorized = true;
            }
            if ( item.onlyCustomer && !customer){
              authorized = false
            }
            if ( item.onlyTransporter  && customer){
              authorized = false
            }
            if (authorized === true){
              return (
                <Menu.Item key={item.path}
                  onClick={() => {
                    props.history.push(item.path);
                    if (window.innerWidth <= windowSizes.small){
                      setState({ sidebarCollapsed: true })
                    }
                  }}>
                  {item.icon && <MCIcon type={item.icon} />}
                  <span>{item.text}</span>
                </Menu.Item>
              )
            }
            return null
          }
        )
    )
    const allMenuItemsPaths = [...mcItems, ...navItems, ...flytwatchItems, ...reportItems, ...mdItems, ...adminItems];
    const findIfPathMatches = (item, pathname) => {
      const { path } = item;
      let splitPath = path.split('/');
      // ['app', 'reports', '<page name>']
      let splitPathname = pathname.split('/');
      // ['app', 'reports', '<page name>', '<sub route1>', '<sub route2>', '<sub routex>']
      if (item.rootPath){
        splitPath = path.replace(item.rootPath, '').split('/')
        splitPathname = pathname.replace(item.rootPath, '').split('/')
      }

      let match = true;

      for (let i=0; i < splitPath.length; i++){
        let itemPathElement = splitPath[i];

        if (splitPathname[i] !== itemPathElement){
          match = false;
          break;
        }
      }
      return match;
    }

    let matchingPath = '';

    allMenuItemsPaths.every(item => {
      let { path, evalPathsFirst } = item;
      
      let match = false;

      if (evalPathsFirst){
        evalPathsFirst.every(p => {
          let subItem = allMenuItemsPaths.find(item => item.path === p);
          if (subItem && findIfPathMatches(subItem, pathname)){
            matchingPath = p;
            match = true;
            return false; // break
          }
          return true
        })
      }

      if (match){
        return false; // break
      }

      match = findIfPathMatches(item, pathname)

      if (match){
        matchingPath = path
        return false; // break
      }

      return true; // continue loop
    });

    let menu = <Menu
        mode="inline"
        selectedKeys={[matchingPath]}
        defaultOpenKeys={persistentData.openKeys || ['manifesting', 'reporting', 'flytwatch', 'masterData', 'admin']}
        onOpenChange={(openKeys) => setPersistentValues({ openKeys })}
        style={{ height: '100%', borderRight: 0 }}
        theme="dark"
    >
      <Menu.SubMenu className='mc-navigation-submenu-manifesting' key="manifesting" title={<span><Icon type="global" /><span>Manifesting</span></span>}>
        {renderMenuItems(mcItems)}
      </Menu.SubMenu>
      {renderMenuItems(navItems)}
      { props.userGroups.includes('flytsuite.flytwatch.view') ? 
        (<Menu.SubMenu className='mc-navigation-submenu-flytwatch' key="flytwatch" title={<span><MCIcon type="flytwatch" /><span>FlytWatch</span></span>}>
          {renderMenuItems(flytwatchItems)}
        </Menu.SubMenu>) : null
      }
      <Menu.SubMenu className='mc-navigation-submenu-report' key="reporting" title={<span><Icon type="file-text" /><span>Report</span></span>}>
          {renderMenuItems(reportItems)}
      </Menu.SubMenu>
      <Menu.SubMenu className='mc-navigation-submenu-masterdata' key="masterData" title={<span><Icon type="database" /><span>Master Data</span></span>}>
          {renderMenuItems(mdItems)}
      </Menu.SubMenu>
      {managementItems.length ?
        <Menu.SubMenu className='mc-navigation-submenu-management' key="management" title={<span><Icon type="team" /><span>Management</span></span>}>
            {renderMenuItems(managementItems)}
        </Menu.SubMenu> : null
      }
      {renderMenuItems(complianceItems).length ?
          <Menu.SubMenu className='mc-navigation-submenu-compliance' key="compliance" title={<span><Icon type="check" /><span>Compliance</span></span>}>
            {renderMenuItems(complianceItems)}
          </Menu.SubMenu> : null
      }
      <AdminSubMenu key="admin" />
    </Menu>

    if (!hasExpanded) menu = null;

    return (
        <Sider className='mc-navigation' width={200} style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%'}} collapsed={sidebarCollapsed} breakpoint="lg">
            {menu}
        </Sider>
    )
}

export default WithOrgData( withUserGroups( withRouter( FlightsNav ) ) )
