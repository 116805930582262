import {Alert, Form} from 'antd';
import React, {forwardRef, LegacyRef} from 'react';
import ComplianceEditor, {ComplianceEditorProps} from "../../editor/compliance-ticket-editor/compliance-editor";
import CenteredLoadingScreen from "../../CenteredLoadingScreen";

export interface ComplianceEntryFormProps {
    editorProps: ComplianceEditorProps
}

const ComplianceEntryForm: React.FC<ComplianceEntryFormProps> = forwardRef(function ComplianceEntryForm({ editorProps }, ref: LegacyRef<Form>) {

    if (editorProps.loading){
        return <CenteredLoadingScreen />
    }

    const errorMsg = editorProps.error ? (
        <Alert
            style={{ marginBottom: 12 }}
            type="error"
            showIcon={true}
            message="Failed to load compliance data"
        />
    ) : null

    return <Form ref={ref} style={{ padding: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
        {errorMsg}
        <ComplianceEditor
            {...editorProps}
        />
    </Form>
})

export default ComplianceEntryForm