import React, {useEffect, useState} from "react";
import {ComplianceRuleFragmentFragment} from "../../../../__generated__/graphql/types";
import mockDb from "../../../../mock/db";
import {ComplianceRuleSelectApi} from "./state";
import ETable from "../../../enchanced-antd-table";
import {ColumnProps} from "antd/lib/table";
import {Button, Col, Divider, Icon, Row, Spin, Tag, Typography} from "antd";
import {useGetComplianceRuleQuery} from "./graphql";

type SelectPanelOptions = {

    onRuleSelected?: (selectedRuleID: string, shortName: string) => void,

    /**
     * @default false
     */
    showCustomer?: boolean,

    disabledComplianceRuleIds?: string[],
    disabledMessage?: string
}

export type SelectPanelProps = SelectPanelOptions & (
    {
        api: ComplianceRuleSelectApi
    } | {
        selectedRuleID?: string,
        ruleList: ComplianceRuleFragmentFragment[]
    }
)

function getStateFromProps(props: SelectPanelProps){
    return {
        selectedRuleID: 'api' in props ? props.api.state.selectedRuleID : props.selectedRuleID,
    }
}

export const SelectPanel: React.FC<SelectPanelProps> = (props) => {
    const state = getStateFromProps(props)
    const { data: selectedRule, loading, error } = useGetComplianceRuleQuery(state.selectedRuleID);

    function handleRuleSelected(ruleID: string, shortName: string){
        if (isDisabled(ruleID)) return;

        if (typeof props.onRuleSelected === 'function'){
            props.onRuleSelected(ruleID, shortName);
        }
    }

    function isSelected(id: string){
        return state.selectedRuleID === id
    }

    function isDisabled(id: string){
        if (!Array.isArray(props.disabledComplianceRuleIds)) return false;

        return props.disabledComplianceRuleIds.includes(id)
    }

    function getColumnTypoType(record: ComplianceRuleFragmentFragment){
        return isSelected(record._id) || isDisabled(record._id) ? "secondary" : undefined as "secondary" | undefined
    }

    const columns: ColumnProps<ComplianceRuleFragmentFragment>[] = [
        {
            key: 'shortName',
            title: 'Short Name',
            dataIndex: 'shortName',
            render: (text, record) => {
                return <Typography.Text type={getColumnTypoType(record)}>{text}</Typography.Text>
            },
            width: 130
        },
        {
            key: 'description',
            title: 'Desc',
            dataIndex: 'description',
            width: 140,
            render: (text, record) => {
                return <div style={{
                    maxWidth: '140px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }}>
                    <span
                        title={text}>
                        <Typography.Text type={getColumnTypoType(record)}>
                            {text}
                        </Typography.Text>
                    </span>
                </div>
            }
        },
        {
            key: 'type',
            title: 'Type',
            dataIndex: 'type',
            render: (text, record) => {
                return <Typography.Text type={getColumnTypoType(record)}>{text}</Typography.Text>
            },
            width: 100
        },
        {
            key: 'category',
            title: 'Category',
            dataIndex: 'category',
            render: (text, record) => {
                return <Typography.Text type={getColumnTypoType(record)}>{text}</Typography.Text>
            },
            width: 100
        },
        {
            key: 'appliesTo',
            title: 'Applies to',
            render: (_, record) => {

                type ApplyEntry = {
                    fieldName: string,
                    value: string
                }

                const applyList: ApplyEntry[] = [];
                function pushApply(fieldName: string, value: string){
                    applyList.push({
                        fieldName: fieldName,
                        value: value
                    })
                }
                if (props.showCustomer && record.customerDoc?.name){
                    pushApply('Customer', record.customerDoc?.name);
                }
                if (record.destinationDoc?.name){
                    pushApply('Destination', record.destinationDoc.name);
                }
                if (record.jobTypeDoc?.name){
                    pushApply('Job Type', record.jobTypeDoc?.name);
                }

                if (!applyList.length && record.customerDoc?.name){
                    pushApply(`All ${record.customerDoc?.name} employees`, undefined);
                }

                const selected = isSelected(record._id);
                const disabled = isDisabled(record._id);

                const rowOpacity = selected || disabled ? 0.5 : 1;

                return (
                    <Row type="flex" gutter={6} style={{ opacity: rowOpacity }}>
                        {applyList.map((data) => {
                            const value = data.value ? `: ${data.value}` : ''
                            return <Col key={data.fieldName}>
                                <Tag>{data.fieldName}{value}</Tag>
                            </Col>
                        })}
                    </Row>
                )
            }
        }
    ]

    const ruleListColumns = [
        ...columns,
        {
            key: 'actions',
            label: '',
            render: (_, record: ComplianceRuleFragmentFragment) => {

                if (isDisabled(record._id)){
                    return <Typography.Text type="warning">{props.disabledMessage}</Typography.Text>
                }

                if (record._id === state.selectedRuleID){
                    return null;
                }
                return <Row type="flex" justify="end">
                    <Col>
                        <Button type="link" size="small">Select</Button>
                    </Col>
                </Row>
            }
        }
    ]

    const ruleList = 'ruleList' in props ? props.ruleList : props.api.state.complianceRules;

    let selectedRuleContent = null;

    if (loading){
        selectedRuleContent = <Spin indicator={<Icon type="loading" />} />
    }

    if (!loading && selectedRule){
        selectedRuleContent = (
            <ETable
                flexMode
                columns={columns}
                pagination={false}
                dataSource={[selectedRule]}
                size="small"
                showHeader={false}
                bordered
                rowKey="_id"
            />
        )
    }

    return (
        <>
            <div style={{
                padding: 8,
                marginBottom: 6
            }}>
                <h4>Current Selection:</h4>
                {selectedRuleContent}
            </div>
            <ETable
                flexMode
                columns={ruleListColumns}
                pagination={false}
                dataSource={ruleList}
                size="small"
                stickyHeader
                rowKey="_id"
                className="mc-table-bordered"
                onRow={(record: ComplianceRuleFragmentFragment) => {
                    return {
                        style: {
                            cursor: record._id !== state.selectedRuleID || !isDisabled(record._id) ? 'pointer' : null
                        },
                        onClick: () => handleRuleSelected(record._id, record.shortName)
                    }
                }}
            />
        </>
    )
}

export default SelectPanel;