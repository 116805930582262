import {FormComponentProps} from "antd/lib/form";
import {Col, DatePicker, Form, Row} from "antd";
import './compliance-ticket-form.style.less';
import React, {HTMLAttributes, useContext, useEffect} from "react";
import {ComplianceTicketFormData} from "./types";
import {ComplianceRuleSelect} from "../../form/select/compliance-rule-select";
import {createFormFields} from "../../../common/form";
import {OrgDataContext} from "../../../context/orgData";
import {OrganizationSelect} from "../../form/select/OrganizationSelect";

export interface ComplianceTicketProps {
    data: ComplianceTicketFormData,
    customerID?: string,
    onChange?: (newFormData: ComplianceTicketFormData) => void,
    wrapperProps?: HTMLAttributes<HTMLDivElement>,
    disabledComplianceRuleIds?: string[],
    disabledComplianceRuleMessage?: string
}

type PropsWithForm = ComplianceTicketProps & FormComponentProps<ComplianceTicketFormData>

enum ComplianceTicketFormFields {
    customerIDAndName = 'customerIDAndName',
    complianceRuleIDAndName = 'complianceRuleIDAndName',
    lastComplianceDate = 'lastComplianceDate',
    nextComplianceDate = 'nextComplianceDate'
}

const ComplianceTicketForm_Internal: React.FC<PropsWithForm> =
    ({
        form,
        wrapperProps,
        customerID,
        disabledComplianceRuleIds,
        disabledComplianceRuleMessage
    }) => {

    const fields: React.ReactNode[] = [];

    if (!customerID){
        fields.push(
            <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Customer" className="ant-form-item-no-margin">
                    {form.getFieldDecorator(ComplianceTicketFormFields.customerIDAndName, {
                        rules: [{ required: true, message: 'Please select a customer' }]
                    })(
                        <OrganizationSelect
                            classTypes={['flytsuite.customer']}
                            labelInValue
                        />
                    )}
                </Form.Item>
            </Col>
        )
    }

    const chosenCustomerID = form.getFieldValue(ComplianceTicketFormFields.customerIDAndName)?.key || customerID;

    if (customerID || (!customerID && form.getFieldValue(ComplianceTicketFormFields.customerIDAndName)?.key)){
        fields.push(
            <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Rule" className="ant-form-item-no-margin">
                    {form.getFieldDecorator(ComplianceTicketFormFields.complianceRuleIDAndName, {
                        rules: [{ required: true, message: 'Please select a compliance rule' }]
                    })(
                        <ComplianceRuleSelect
                            customerId={chosenCustomerID}
                            disabledComplianceRuleIds={disabledComplianceRuleIds}
                            disabledMessage={disabledComplianceRuleMessage}
                        />
                    )}
                </Form.Item>
            </Col>
        )
    }

    if (form.getFieldValue(ComplianceTicketFormFields.complianceRuleIDAndName)){
        fields.push(...[
            <Col xs={24} sm={6} lg={6}>
                <Form.Item label="Start Date" className="ant-form-item-no-margin">
                    {form.getFieldDecorator(ComplianceTicketFormFields.lastComplianceDate, {
                        rules: [{
                            required: true, message: 'Please choose a start date'
                        }]
                    })(
                        <DatePicker style={{ width: '100%' }} />
                    )}
                </Form.Item>
            </Col>,
            <Col xs={24} sm={6} lg={6}>
                <Form.Item label="End Date" className="ant-form-item-no-margin">
                    {form.getFieldDecorator(ComplianceTicketFormFields.nextComplianceDate, {
                        rules: [{
                            required: true, message: 'Please choose an end date'
                        }]
                    })(
                        <DatePicker style={{ width: '100%' }} />
                    )}
                </Form.Item>
            </Col>
        ])
    }

    const content = (
        <Row gutter={6}>
            {fields}
        </Row>
    )

    return <div
        className="compliance-ticket-form"
        {...wrapperProps}
    >{content}</div>
}

const ComplianceTicketForm = Form.create<PropsWithForm>({
    mapPropsToFields({ data }){
        return createFormFields(data);
    },
    onFieldsChange(props, changedFields){
        if (typeof props.onChange === 'function'){
            props.onChange({
                ...props.data,
                ...changedFields
            });
        }
    }
})(ComplianceTicketForm_Internal)

ComplianceTicketForm.displayName = 'ComplianceTicketForm'

export default ComplianceTicketForm