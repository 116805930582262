import {ComplianceTicket, ComplianceTicketFragmentFragment} from "../../../__generated__/graphql/types";
import {ComplianceTicketFormData} from "./types";
import {createFormField, createFormFields, getLabelInValue, LabelInValue} from "../../../common/form";
import {Moment} from "moment";
import {momentOrNull} from "../../../common/util";
import uuidv4 from 'uuid/v4';
import {ComplianceEditorApi, State} from "./state";
import {CollapsePanelProps, CollapseProps} from "antd/lib/collapse";

/**
 * Converts a ComplianceTicket object into form fields and converts dates to moments
 * @param ticket ComplianceTicket to enhance
 * @param internalId Optionally choose an internal ID, otherwise one is generated.
 * @private
 */
export function toFormData(ticket: ComplianceTicketFragmentFragment | ComplianceTicketFormData, internalId?: string): ComplianceTicketFormData {
    if ('complianceRuleIDAndName' in ticket){
        return ticket;
    }

    const nonFormTicket = ticket as ComplianceTicket;

    return {
        _id: nonFormTicket._id,
        internalId: internalId || uuidv4(),
        customerIDAndName: createFormField<LabelInValue>(getLabelInValue(nonFormTicket.customerDoc, 'name')),
        personID: nonFormTicket.personID,
        customerDoc: nonFormTicket.customerDoc,
        complianceRuleDoc: nonFormTicket.complianceRuleDoc,
        nextComplianceDate: createFormField<Moment>(momentOrNull(nonFormTicket.nextComplianceDate)),
        lastComplianceDate: createFormField<Moment>(momentOrNull(nonFormTicket.lastComplianceDate)),
        complianceRuleIDAndName: createFormField<LabelInValue>(getLabelInValue(nonFormTicket.complianceRuleDoc, 'shortName'))
    }
}

export function getCollapsePanelKey(ticket: ComplianceTicketFormData): string {
    return ticket.internalId;
}