import {
    ComplianceRuleFragmentFragment,
    ComplianceTicket, Customer,
    OrganizationFragmentFragment
} from "__generated__/graphql/types";
import {Collapse, message} from "antd";
import ComplianceTicketComponent from './compliance-ticket-form';
import ComplianceHeader from "./compliance-header";
import './compliance-editor.style.less';
import React, {Dispatch, useEffect, useReducer, useRef, useState} from "react";
import mockDb from "../../../mock/db";
import * as State from './state';
import {Action, ComplianceEditorApi} from "./state";
import {getCollapsePanelKey, toFormData} from "./util";
import {useApolloClient} from "react-apollo";
import {ComplianceRuleFragment} from "../../../Queries/Compliance";
import {usePersonComplianceTicketsMutation, usePersonComplianceTicketsQuery} from "./graphql";
import {ComplianceTicketFormValues} from "./types";
import { difference } from "lodash";
import NonIdealState from "../../NonIdealState";
import {OrganizationFragment} from "../../../Queries/Organization";

function useFormRefManager(){
    const formRefs = useRef(new Map<string, any>());

    function removeBrokenRefs(){
        const entries = Array.from(formRefs.current.entries());

        for (let entry of entries) {
            if (entry[1] === null || entry[1] === undefined){
                formRefs.current.delete(entry[0]);
            }
        }
    }

    function clear(){
        formRefs.current = new Map();
    }

    function add(internalId: string, ref: any){
        formRefs.current.set(internalId, ref);
    }

    function remove(internalId: string){
        formRefs.current.delete(internalId);
    }

    return {
        add,
        remove,
        clear,
        getFormRefs: () => {
            removeBrokenRefs();
            return formRefs.current;
        }
    }
}

export interface UseComplianceEditorPropsOptions {
    customerID?: string,
    onSaveSuccess?: () => void,
    onFormStateChanged?: (formState: State.State['formState']) => void
}

export function useComplianceEditorProps(personID: string, options?: UseComplianceEditorPropsOptions){

    const apolloClient = useApolloClient();
    const { data, loading, error } = usePersonComplianceTicketsQuery({
        personID: personID,
        limit: 9999999
    });

    const extendedReducer: React.Reducer<State.State, State.Action> = (state, action) => {
        const stateResult = State.Reducer(state, action);

        if (typeof options?.onFormStateChanged === 'function'){
            options.onFormStateChanged(stateResult.formState);
        }

        return stateResult;
    }

    const [ saveData, saveDataResult ] = usePersonComplianceTicketsMutation();

    const [ state, dispatch ] = useReducer(extendedReducer, State.DefaultState);

    const formRefManager = useFormRefManager();

    function getDeletedTickets(idsInForm: string[]){
        const queriedIds = data.map((datum) => datum._id);
        return difference(queriedIds, idsInForm);
    }

    function handleSubmit(formValues: Map<string, ComplianceTicketFormValues>){
        const displayedTickets = Array.from(state.formState.values());
        const deletedTicketIds = getDeletedTickets(displayedTickets.map(t => t._id));

        const formValuesList = Array.from(formValues.entries());

        saveData({
            personID: personID,
            deleteTicketIds: deletedTicketIds,
            updateTickets: formValuesList.map(([internalId, values ]) => {

                // Get customerID value from form data, or if that's not available, get it from the options.
                let customerID = state.formState.get(internalId)?.customerIDAndName?.value?.key || options?.customerID;

                return {
                    _id: values._id,
                    customerID: customerID,
                    complianceRuleID: values.complianceRuleIDAndName?.key,
                    lastComplianceDate: values.lastComplianceDate?.format('YYYY-MM-DD'),
                    nextComplianceDate: values.nextComplianceDate?.format('YYYY-MM-DD')
                }
            })
        })
        .then(() => {
            message.success('Compliance tickets saved successfully');
            if (typeof options?.onSaveSuccess === 'function'){
                options.onSaveSuccess();
            }
        })
        .catch(() => {
            message.error('Failed to save compliance tickets')
        })
    }

    function onSave(){
        const formRefs = formRefManager.getFormRefs();

        const validForms = new Map<string, ComplianceTicketFormValues>();
        let hasErrors = false;

        formRefs.forEach((form, internalId) => {
            form.validateFields((err, values) => {
                if (err){
                    hasErrors = true;
                }
                else {
                    validForms.set(internalId, values);
                }
            })
        })

        if (!hasErrors){
            handleSubmit(validForms);
        }
    }

    function initTickets(){
        if (data){
            dispatch({ type: 'INIT_TICKETS', payload: data });
        }
        formRefManager.clear();
    }

    useEffect(() => {
        initTickets();
    }, [ data ]);

    // Adds some side effects to the dispatcher
    const dispatcher: Dispatch<State.Action> = async (action) => {
        if (action.type === 'UPDATE_TICKET'){

            const formData = toFormData(action.payload.data, action.payload.internalId);

            // When the user changes the complianceRuleIDAndName field in the form, we need to ensure that
            // the complianceRuleDoc is updated to match. Likelyhood is, the ComplianceRule was loaded into the cache
            // when the user selected it, so we should be able to pull that data in.
            if (state.formState.has(formData.internalId)){

                const ticketData = state.formState.get(formData.internalId);
                if (
                    ticketData.complianceRuleDoc?._id !== formData.complianceRuleIDAndName?.value?.key &&
                    ticketData.complianceRuleIDAndName?.value?.key
                ){
                    // Fetch complianceRuleDoc from cache
                    const doc = apolloClient.readFragment<ComplianceRuleFragmentFragment>({
                        id: 'ComplianceRule:' + ticketData.complianceRuleIDAndName.value.key,
                        fragment: ComplianceRuleFragment,
                        fragmentName: 'ComplianceRuleFragment'
                    })

                    if (doc){
                        action.payload.data.complianceRuleDoc = doc;
                    }
                }

                // Implement the same for customerIDAndName (doesn't seem to work)
                if (
                    ticketData.customerDoc?._id !== formData.customerIDAndName?.value?.key &&
                    ticketData.customerIDAndName?.value?.key
                ){
                    // Fetch complianceRuleDoc from cache
                    const doc = apolloClient.readFragment<OrganizationFragmentFragment>({
                        id: 'Organization:' + ticketData.customerIDAndName.value.key,
                        fragment: OrganizationFragment,
                        fragmentName: 'OrganizationFragment'
                    })

                    if (doc){
                        action.payload.data.customerDoc = doc as Customer;
                    }
                }
            }
        }

        if (action.type === 'REMOVE_TICKET'){
            formRefManager.remove(action.payload.internalId);
        }

        if (action.type === 'INIT_TICKETS'){
            formRefManager.clear();
        }

        dispatch(action);
    }

    return {
        customerID: options?.customerID,
        loading: loading,
        error: error,
        state: state,
        saving: saveDataResult.loading,
        addFormRef: formRefManager.add,
        save: onSave,
        api: new ComplianceEditorApi(state, dispatcher, {
            onResetTickets: () => initTickets()
        })
    }
}

export type ComplianceEditorProps = Pick<
    ReturnType<typeof useComplianceEditorProps>,
    'state' | 'api' | 'addFormRef' | 'customerID' | 'loading' | 'error'
>

const ComplianceEditor: React.FC<ComplianceEditorProps> = (
    {
        state,
        api,
        addFormRef,
        customerID
    }
) => {

    const usedComplianceRuleIds = new Map<string, null>();

    state.formState.forEach((ticket) => {
        if (ticket.complianceRuleIDAndName?.value?.key){
            usedComplianceRuleIds.set(ticket.complianceRuleIDAndName?.value?.key, null);
        }
    })

    const tickets = Array.from(state.formState.values())
        .map((data) => {

            const header = <ComplianceHeader data={data} api={api} />

            return (
                <Collapse.Panel key={getCollapsePanelKey(data)} header={header}>
                    <ComplianceTicketComponent
                        data={data}
                        customerID={customerID}
                        ref={(ref) => addFormRef(data.internalId, ref)}
                        disabledComplianceRuleIds={Array.from(usedComplianceRuleIds.keys())}
                        disabledComplianceRuleMessage="Rule already assigned"
                        onChange={(newData) => {
                            api.updateTicket(newData);
                        }}
                    />
                </Collapse.Panel>
            )
    })

    if (!tickets.length){
        return (
            <NonIdealState
                title="Add Compliance Tickets"
                description={"Click \"Add\" to add a personnel compliance record"}
                icon="check-circle"
                type="primary"
            />
        )
    }

    return (
        <Collapse
            className="compliance-editor mc-collapse-rounded"
            activeKey={state.collapseActiveKeys}
            onChange={api.setCollapseKeys}
        >
            {tickets}
        </Collapse>
    )
}

export default ComplianceEditor;