import { Cell, Column } from '@blueprintjs/table';
import { Button, Col, Divider, Input, List, message, Row } from 'antd';
import { getLabelInValue, getLabelInValueKey } from 'common/form';
import { getColWidths } from 'common/table';
import { castArray, getFirstElArrayOrNull, safeGet } from 'common/util';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import { buildBulkMutation } from 'components/covid-vax-date-form/submitter';
import { PersonCovidVaxRecord } from 'components/covid-vax-date-list';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import { getFormCredentials } from 'components/PersonEditor';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import credentialTypes from 'credentialTypes';
import gql from 'graphql-tag';
import useUserGroups from 'hooks/useUserGroups';
import { sha256 } from 'js-sha256';
import { isEmpty, keyBy, mapKeys, omit } from 'lodash';
import moment from 'moment';
import DeleteEntity from 'Mutations/DeleteEntity';
import pluralize from 'pluralize';
import React, {useContext, useEffect, useRef, useState} from 'react';
import { useApolloClient } from 'react-apollo';
import { DuplicationChecker, MDDetails, MDHeader, MDLayout, MDTable, TableLoader, useMasterDataState } from '..';
import ComplianceForm from '../entryForms/compliance';
import { getMDTableProps, MasterDataProps, MDStateToDetailsProps, MDStateToHeaderProps, MDStateToLayoutProps, MDStateToTableScrollerProps, renderCellFromKeyIndex, renderNoData, renderOrgCell, searchValuesToQuerySearchPayload, trimObjectStringValues, trimString, updateQueryWithFetchMoreResult } from '../util';
import {useComplianceEditorProps} from "../../editor/compliance-ticket-editor/compliance-editor";
import {OrgDataContext} from "../../../context/orgData";

export type HidableColumnTypes = 'customer' | 'employer'

export interface MDComplianceProps extends WithOrgDataProps, MasterDataProps {

}

const QUERY = gql`
    query PersonnelQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $credFilter: String!, $filteringByID: Boolean!, $limit: Int, $skip: Int) {
        MasterDataPersonnel(filter: $filter, search: $search, limit: $limit, skip: $skip) @skip(if: $filteringByID) {
            docs {
                ... on Person {
                    _id
                    _rev
                    firstName
                    lastName
                    dob
                    extraBroadState
                    contactInfo {
                        address {
                            street
                            city
                            state
                            zipCode
                        }
                        email {
                            address
                            type
                        }
                        phone {
                            number
                            type
                        }
                    }
                    credentials {
                        _id
                        type
                        key
                        expiration
                    }
                    employerID {
                        _id
                        name
                    }
                    customerID {
                        _id
                        name
                    }
                    essentialOn {
                        _id
                        locationID {
                            _id
                            name
                        }
                    }
                }
            }
        }
        getPersonsByCred (
            search: $credFilter
        ) @include(if: $filteringByID) {
            _id
            _rev
            firstName
            lastName
            dob
            extraBroadState
            contactInfo {
                address {
                    street
                    city
                    state
                    zipCode
                }
                email {
                    address
                    type
                }
                phone {
                    number
                    type
                }
            }
            credentials {
                _id
                type
                key
                expiration
            }
            employerID {
                _id
                name
            }
            customerID {
                _id
                name
            }
        }
    }
`

const MUTATION = gql`
mutation SaveCompliance {
    DontCare
}
`

const MDCompliance: React.FC<MDComplianceProps> = (props) => {
    const orgData = useContext(OrgDataContext);

    const state = {
        queryGQL: QUERY,
        deleteMutationGQL: DeleteEntity,
        saveMutationGQL: MUTATION,
        getQueryData: data => {
            return data.MasterDataPersonnel.docs;
        },
        getQueryVariables: (searchValues) => {
            let filter = [];

            if (props.orgData.getOrgIDByType('employer')){
                filter.push({
                    key: 'employerID',
                    value: JSON.stringify(props.orgData.getOrgIDByType('employer'))
                })
            }else if (searchValues.employer){
                filter.push({
                    key: 'employerID',
                    value: JSON.stringify(getLabelInValueKey(searchValues.employer))
                })
            }
            if (props.orgData.getOrgIDByType('customer')){
                filter.push({
                    key: 'customerID',
                    value: JSON.stringify(props.orgData.getOrgIDByType('customer'))
                })
            }
            else if (searchValues.customer){
                filter.push({
                    key: 'customerID',
                    value: JSON.stringify(getLabelInValueKey(searchValues.customer))
                })
            }
            return {
                filter: filter.length ? filter : undefined,
                search: searchValuesToQuerySearchPayload(omit(searchValues, ['employer', 'customer'])),
                filteringByID: searchValues.credKey ? true : false,
                credFilter: searchValues.credKey + ""
            }

        },
        paginationLimit: props.dataPaginationLimit,
        pollInterval: 0,
        updateQueryAfterFetchMore: updateQueryWithFetchMoreResult('MasterDataPersonnel'),
        transformEditFromExisting: async (values) => {
            return {
                ...values,
                formState: null
            }
        },
        lockFieldsToValue: {
            customerID: getLabelInValue(props.orgData.getOrgByType('customer'), 'name'),
            employerID: getLabelInValue(props.orgData.getOrgByType('employer'), 'name')
        }
    }

    const MDState = useMasterDataState(state);
    const personID = MDState.entryFields?._id?.value;

    const complianceEditorProps = useComplianceEditorProps(personID, {
        customerID: orgData.customerID,
        onSaveSuccess: () => MDState.cancelEditing(),
        onFormStateChanged: (formState) => MDState.setEntryFields({
            _id: MDState.entryFields?._id,
            formState: formState
        })
    });

    let columnElements = [
        <Column name="Last Name" cellRenderer={renderCellFromKeyIndex(MDState.data, 'lastName')} />,
        <Column name="First Name" cellRenderer={renderCellFromKeyIndex(MDState.data, 'firstName')} />,
        <Column name="DOB" cellRenderer={(idx) => {
            let record = MDState.data[idx];
            return <Cell>{record.dob && moment(record.dob).format('MM-DD-YYYY')}</Cell>
        }} />
    ]

    if(!props.orgData.getOrgIDByType('employer')){
        columnElements.push(
            <Column name="Employer" cellRenderer={renderOrgCell(MDState.data, 'employerID')} />
        )
    }

    if (!props.orgData.getOrgIDByType('customer')){
        columnElements.push(
            <Column name="Customer" cellRenderer={renderOrgCell(MDState.data, 'customerID')} />
        )
    }

    columnElements.push(
        <Column name="City, State" cellRenderer={(idx) => {
            let record = MDState.data[idx];
            let city = safeGet(['contactInfo', 'address', 'city'], record)
            let state = safeGet(['contactInfo', 'address', 'state'], record)
            let text: string;
            if (city && !state) text = city
            else if (state && !city) text = state
            else if (!state && !city) text = null
            else text = `${city}, ${state}`
            return <Cell>{text}</Cell>
        }} />
    )

    function getLabelHeaderStyle(){
        let style: React.CSSProperties = {
            marginBottom: 0,
            marginRight: 12
        }
        if (MDState.isEditing){
            style = {
                ...style,
                textAlign: 'right',
                width: '4rem'
            }
        }
        return style
    }

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                lastName: MDState.getSearchValue('lastName'),
                firstName: MDState.getSearchValue('firstName'),
                employer: MDState.getSearchValue('employer'),
                customer: MDState.getSearchValue('customer')
            })
        })}
        headerElement={
            <MDHeader
                hasFiltersApplied={!isEmpty(MDState.searchValues)}
                onFiltersClear={() => MDState.clearSearchValues()}
                {...MDStateToHeaderProps(MDState)}
                onNewEntry={null}
                inputElement={
                    <Row type={(MDState.isEditing) ? undefined : "flex"} gutter={12}>
                        <Col>
                            <Row type="flex" align="middle">
                                <Col><h4 style={getLabelHeaderStyle()}>Search:</h4></Col>
                                <Col>
                                    <Input.Group compact>
                                        <Input
                                            onChange={(e) => {
                                                MDState.onSearchValueChange('lastName', e.target.value)
                                            }}
                                            placeholder="By last name"
                                            value={MDState.getSearchValue('lastName')}
                                            style={{ width: '10rem' }}
                                            allowClear
                                        />
                                        <Input
                                            onChange={(e) => {
                                                MDState.onSearchValueChange('firstName', e.target.value)
                                            }}
                                            placeholder="By first name"
                                            value={MDState.getSearchValue('firstName')}
                                            style={{ width: '10rem' }}
                                            allowClear
                                        />
                                    </Input.Group>
                                </Col>
                            </Row>
                        </Col>
                        {!(MDState.isEditing) ? <Divider type="vertical" style={{ height: 'auto' }} /> : null}
                        <Col style={(MDState.isEditing) ? { marginTop: 12 } : undefined }>
                            <Row type="flex" align="middle">
                                <Col><h4 style={getLabelHeaderStyle()}>Filter:</h4></Col>
                                <Col>
                                    <Row type="flex" gutter={12}>
                                        {!props.orgData.getOrgIDByType('employer') ? <Col>
                                            <OrganizationSelect
                                                classTypes={['flytsuite.employer']}
                                                onChange={(value) => MDState.onSearchValueChange('employer', value)}
                                                value={MDState.getSearchValue('employer')}
                                                placeholder="By employer"
                                                style={{ width: '10rem' }}
                                                labelInValue
                                            />
                                        </Col>: null }

                                        {!props.orgData.getOrgIDByType('customer') ? (
                                            <Col>
                                                <OrganizationSelect
                                                    classTypes={['flytsuite.customer']}
                                                    onChange={(value) => MDState.onSearchValueChange('customer', value)}
                                                    value={MDState.getSearchValue('customer')}
                                                    placeholder="By customer"
                                                    style={{ width: '10rem' }}
                                                    labelInValue
                                                />
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            />
        }
        tableElement={
            <TableLoader mdState={MDState}>
                <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                    <MDTable
                        {...getMDTableProps(MDState.data, MDState)}
                        columnWidths={getColWidths(columnElements.length, {
                            0: 134,
                            1: 134,
                            2: 100,
                            [props.orgData.getOrgIDByType('customer') || props.orgData.getOrgIDByType('employer') ? 3 : 4]: 600
                        }, 200)}
                        hideDelete
                    >
                        {columnElements}
                    </MDTable>
                </BlueprintTableInfiniteScroller>
            </TableLoader>
        }
        detailsElement={
            <MDDetails
                {...MDStateToDetailsProps(MDState, (entry) => `Compliance for ${entry.lastName}, ${entry.firstName}`)}
                contentWidth="68rem"
                extra={[
                    <Button
                        disabled={!complianceEditorProps.api.areTicketsDirty() || complianceEditorProps.loading}
                        onClick={complianceEditorProps.api.resetTickets}
                    >
                        Reset
                    </Button>,
                    <Button
                        type="primary"
                        icon="plus"
                        disabled={complianceEditorProps.loading}
                        onClick={() => {
                            const customer = orgData.getOrgByType('customer');
                            complianceEditorProps.api.addBlankTicket(customer, personID, { makeVisible: true });
                        }}
                    >
                        Add
                    </Button>
                ]}
                onSave={complianceEditorProps.save}
                disableSave={!complianceEditorProps.state.ticketsDirty}
                saving={complianceEditorProps.saving}
            >
                <ComplianceForm
                    editorProps={complianceEditorProps}
                />
            </MDDetails>
        }
    />
}

export default WithOrgData(MDCompliance)