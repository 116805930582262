import {
    ComplianceRuleFragmentFragment,
    MangoQueryResult,
    QueryCustomerComplianceRulesArgs, QueryGetComplianceRuleByIdArgs
} from "../../../../__generated__/graphql/types";
import gql from "graphql-tag";
import {ComplianceRuleFragment} from "../../../../Queries/Compliance";
import {useQuery} from "react-apollo";

const QUERY_CUSTOMER_COMPLIANCE_RULES = gql`
    query ComplianceRuleSelect_ComplianceRulesQuery(
        $customerID: ID!
        $limit: Int
        $skip: Int
        $bookmark: String
    ){
        CustomerComplianceRules(customerID: $customerID, limit: $limit, bookmark: $bookmark, skip: $skip){
            docs {
                ... on ComplianceRule {
                    ...ComplianceRuleFragment
                }
            }
            bookmark
        }
    }
    ${ComplianceRuleFragment}
`

const QUERY_GET_COMPLIANCE_RULE = gql`
    query ComplianceRuleSelect_GetComplianceRule($id: ID!){
        GetComplianceRuleByID(id: $id){
            ...ComplianceRuleFragment
        }
    }
    ${ComplianceRuleFragment}
`

export interface UseComplianceDataArgs {
    customerID: string,
    limit?: number,
    skip?: number,
    bookmark?: string
}

export function useCustomerComplianceRuleQuery(args: UseComplianceDataArgs){

    type QueryResult = {
        CustomerComplianceRules: MangoQueryResult
    }

    const result = useQuery<QueryResult, QueryCustomerComplianceRulesArgs>(QUERY_CUSTOMER_COMPLIANCE_RULES, {
        variables: {
            customerID: args.customerID,
            limit: args.limit,
            skip: args.skip,
            bookmark: args.bookmark
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    })

    return {
        ...result,
        data: result?.data?.CustomerComplianceRules?.docs as ComplianceRuleFragmentFragment[]
    }
}

export interface UseComplianceDataArgs {
    customerID: string,
    limit?: number,
    skip?: number,
    bookmark?: string
}

export function useGetComplianceRuleQuery(complianceRuleID: string){
    type QueryResult = {
        GetComplianceRuleByID: ComplianceRuleFragmentFragment
    }

    const result = useQuery<QueryResult, QueryGetComplianceRuleByIdArgs>(QUERY_GET_COMPLIANCE_RULE, {
        variables: {
            id: complianceRuleID
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
    })

    return {
        ...result,
        data: result?.data?.GetComplianceRuleByID
    }
}