import {Button, Col, Row, Typography} from "antd";
import {momentOrNull} from "../../../common/util";
import './compliance-header.style.less'
import moment, { isMoment } from "moment";
import {ComplianceTicketFormData} from "./types";
import React from "react";
import {ComplianceEditorApi} from "./state";

export interface ComplianceHeaderProps {
    data: ComplianceTicketFormData,
    api: ComplianceEditorApi
}

const ComplianceHeader: React.FC<ComplianceHeaderProps> = ({ data, api }) => {

    const nextDate = momentOrNull(data?.nextComplianceDate?.value);
    const lastDate = momentOrNull(data?.lastComplianceDate?.value);

    let dateStatus: React.ReactNode = '';

    const statusClassName = "compliance-header-status";

    if (isMoment(nextDate) && moment().isAfter(nextDate)){
        dateStatus = <Typography.Text className={statusClassName} type="danger">EXPIRED</Typography.Text>
    }
    else if (isMoment(nextDate) && moment().add(1, 'month').isAfter(nextDate)){
        dateStatus = <Typography.Text className={statusClassName} type="warning">EXPIRES SOON</Typography.Text>
    }
    else if (isMoment(nextDate)) {
        dateStatus = <Typography.Text className={statusClassName} style={{ color: 'green' }}>
            VALID
        </Typography.Text>
    }

    dateStatus = dateStatus ? <span>{dateStatus}</span> : dateStatus;

    let shortName: React.ReactNode = data?.complianceRuleIDAndName?.value?.label || data?.complianceRuleDoc?.shortName;
    if (!shortName && !data?.complianceRuleDoc){
        shortName = <strong>Select Compliance Rule</strong>;
    }

    let customerName = data?.customerDoc?.name;
    if (customerName){
        customerName = `(${customerName})`
    }

    return (
        <>
            <Row type="flex" align="middle" gutter={24} className="compliance-header">
                <Col style={{ maxWidth: 250, width: 250 }}>
                    <p style={{ margin: 0 }}>{shortName} <span style={{ marginLeft: 3 }}>{customerName}</span></p>
                    <Typography.Text type="secondary">{data.complianceRuleDoc?.description}</Typography.Text>
                </Col>
                {lastDate ? (
                    <Col>
                        <Typography.Text type="secondary">
                            Start date:
                        </Typography.Text>{' '}
                        {lastDate?.format('YYYY-MM-DD')}
                    </Col>
                ) : null}
                {nextDate ? (
                    <Col>
                        <Typography.Text type="secondary">
                            End Date:
                        </Typography.Text>{' '}
                        {nextDate?.format('YYYY-MM-DD')}
                    </Col>
                ) : null}
                <Col>
                    {dateStatus}
                </Col>
                <Col className="compliance-header-delete-wrapper">
                    <Button
                        type="danger"
                        ghost
                        icon="delete"
                        size="small"
                        onClick={() => api.removeTicket(data)}
                    />
                </Col>
            </Row>
        </>

    )
}

export default ComplianceHeader