import {Icon, Menu} from 'antd';
import useUserGroups from 'hooks/useUserGroups';
import React from 'react';
import 'css/Navigation.less';

function EmployerNav(props) {
    const {...restProps} = props;

    const [userGroups] = useUserGroups();

    return <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={['masterdata', 'management', 'compliance']}
        {...restProps}
    >
        <Menu.Item key="passengers">
            <Icon type="team"/>
            <span>Passengers</span>
        </Menu.Item>
        <Menu.SubMenu key="masterdata" className='mc-navigation-submenu-masterdata'
                      title={<span><Icon type="database"/><span>Master Data</span></span>}>
            <Menu.Item key="masterdata/personnel">Personnel</Menu.Item>
        </Menu.SubMenu>
        {userGroups.findIndex(g => g.includes('management') || g === 'flytsuite.master.all') > -1 &&
            <Menu.SubMenu className='mc-naviagation-submenu-management' key="management"
                          title={<span><Icon type="team"/><span>Management</span></span>}>
                {userGroups.findIndex(g => g.includes('management.all') || g.includes('management.emailtriggers.all') || g === 'flytsuite.master.all') > -1 && (
                    <Menu.Item key="management/email-triggers">Email Triggers</Menu.Item>
                )}
            </Menu.SubMenu>
        }
        {userGroups.includes('flytsuite.compliance.ticket.all') ? (
            <Menu.SubMenu key="compliance" className="mc-navigation-submenu-compliance"
                          title={<span><Icon type="check"/><span>Compliance</span></span>}>
                <Menu.Item key="compliance/personnel">Personnel</Menu.Item>
            </Menu.SubMenu>
        ) : null}
    </Menu>
}

export default EmployerNav