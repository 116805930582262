import {ComplianceRuleFragmentFragment} from "../../../../__generated__/graphql/types";
import {produce} from "immer";
import React, {useEffect, useReducer} from "react";

export const DefaultState = {
    drawerOpen: false,
    selectedRuleID: null as string
}

export type State = typeof DefaultState;

export type Action = {
    type: 'OPEN_PANEL',
    payload: {
        selectedRuleID: string
    }
} | {
    type: 'CLOSE_PANEL'
}

export const Reducer: React.Reducer<State, Action> = (_state, action) => {
    return produce(_state, (draft) => {
        switch (action.type){
            case 'OPEN_PANEL':
                draft.drawerOpen = true;
                draft.selectedRuleID = action.payload.selectedRuleID;
                return draft;
            case 'CLOSE_PANEL':
                draft.drawerOpen = false;
                return draft;
            default:
                return draft;
        }
    })
}

export function useComplianceRuleSelectApi(data: ComplianceRuleFragmentFragment[]){

    const [ state, dispatch ] = useReducer(Reducer, DefaultState);

    return {
        state: {
            ...state,
            complianceRules: data
        },
        openPanel: (selectedRuleID: string) => dispatch({ type: 'OPEN_PANEL', payload: { selectedRuleID } }),
        closePanel: () => dispatch({ type: 'CLOSE_PANEL' })
    }
}

export type ComplianceRuleSelectApi = ReturnType<typeof useComplianceRuleSelectApi>

export const ComplianceRuleSelectApiContext = React.createContext<ComplianceRuleSelectApi>(null);